export const ACCESS_TOKEN = "Access_Token";
export const REFRESH_TOKEN = "Refresh_Token";
export const ORG_ID = "Org_Id";
export const APP_ID = "App_Id";
export const CACHED_IMAGE_URLS = "Cached_Image_Urls"
export const GLOBAL_CONFIG = "Global_Config";
export const GLOBAL_CONFIG_KEYS = {
    DATE_FORMAT: "date format",
    PARTIAL_EMI_PAYMENT_DATE_LIMIT: "partial emi payment date limit"
}
export const RegexPatterns = {
    email: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
    username: /^[a-zA-Z0-9_]{3,20}$/,
    password: /^(?=.[a-z])(?=.[A-Z])(?=.\d)(?=.[@$!%?&])[A-Za-z\d@$!%?&]{8,}$/,
    phone: /^\d{3}-?\d{3}-?\d{4}$/,
    url: /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/,
    date: /^\d{4}-\d{2}-\d{2}$/,
    creditCard: /^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|3[47][0-9]{13}|6(?:011|5[0-9]{2})[0-9]{12})$/,
    ipv4: /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/,
    hexColor: /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/,
    zipCode: /^\d{5}(?:-\d{4})?$/,
    nameWithUnderscore: /^[a-zA-Z0-9_]*$/,
    lowerCaseWithUnderscore: /^[a-z_]*$/,
    nameWithoutDigit: /^\D*$/,
    // namewithSpecChars: /^[a-zA-Z0-9!@#$%^&*)(+=._-\s]*$/,
    isoString: /^[0-9]{4}-((0[13578]|1[02])-(0[1-9]|[12][0-9]|3[01])|(0[469]|11)-(0[1-9]|[12][0-9]|30)|(02)-(0[1-9]|[12][0-9]))T(0[0-9]|1[0-9]|2[0-3]):(0[0-9]|[1-5][0-9]):(0[0-9]|[1-5][0-9])\.[0-9]{3}Z$/,
    dateWithTimezoneString: /^((19|20)[0-9][0-9])[-](0[1-9]|1[012])[-](0[1-9]|[12][0-9]|3[01])[T]([01][0-9]|[2][0-3])[:]([0-5][0-9])[:]([0-5][0-9])([+|-]([01][0-9]|[2][0-3])[:]([0-5][0-9])){0,1}$/,
    capitalize: /[A-Z][a-zA-Z]*/,
    otp: /^[A-Za-z0-9]{6}$/,
    panNumber: /[A-Z]{5}[0-9]{4}[A-Z]{1}/,
    gstNumber: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
}
export const FilterTypes = {
    EQUAL_TO: "equal_to",
    NOT_EQUAL_TO: "not_equal_to",
    BETWEEN: "between",
    LESS_THAN: "less_than",
    LESS_THAN_OR_EQUAL_TO: "less_than_or_equal_to",
    GREATER_THAN: "greater_than",
    GREATER_THAN_OR_EQUAL_TO: "greater_than_or_equal_to",
    SORT_BY: "sort_by",
    LIKE: "like",
    LIMIT: "limit",
    LESS_THAN_BETWEEN_COLUMNS: "less_than_between_columns",
    GREATER_THAN_BETWEEN_COLUMNS: "greater_than_between_columns",
    COUNT_ALL_ROWS: "count_all_rows",
};
export const PaymentStatus = {
    PAID: "paid",
    UNPAID: "unpaid",
    PARTIALLY_PAID: "partially paid"
}
export const Purchase = 'purchase';
export const Sales = 'sales';
export const ADMIN = 'admin';
export const PAGINATION_MODEL_DEFAULT = { page: 0, pageSize: 10 };
export const DEBOUNCE_DELAY = 500;
export const INVOICE_TEMPLATES = {
    ADVANCED_GST: "advanced_gst",
    ADVANCED_GST_TALLY: "advanced_gst_tally",
    SIMPLE: "simple",
    MODERN: "modern",
    STYLISH: "stylish",
    BILLBOOK: "billbook",
    BILLBOOK_A5: "billbook_a5",
    ADVANCED_GST_A5: "advanced_gst_a5",
}
export const THEME_SETTINGS_KEYS: any = {
    show_party_balance: "Show party balance in invoice",
    show_item_description: "Show item description in invoice",
    show_phone_number: "Show phone number on invoice",
    show_address: "Show address on invoice",
    show_pan_and_gst: "Show PAN and GST no on invoice",
    show_emi_details: "Show EMI details on invoice",
    show_ship_to_details: "Show Ship to details on invoice",
}
export const ITEM_TABLE_COLUMNS_KEYS: any = {
    show_rate_column: `Price/Item (\u20B9)`,
    show_quantity_column: "Quantity",
    show_expiry_date_column: "Exp. Date",
    show_batch_no_column: "Batch No.",
    show_mrp_column: "Mrp",
    show_tax_column: "Tax",
    show_savings_column: "Savings",
}

export const entityNames: any = {
    tbl_settings: 'Preferences',
    tbl_users: 'Users',
    tbl_user_roles: 'Roles',
    tbl_sales_details: "Sales",
    tbl_purchase_details: "Purchase",
    tbl_sales_items: "Sales",
    tbl_purchase_items: "Purchase",
    tbl_item_master: "Inventory",
    tbl_item_details: "Inventory",
    tbl_customer: "Customer",
    tbl_supplier: "Suppliers",
    tbl_receipts: "Receipts/Payment",
    tbl_ledger: "Ledger",
    tbl_ledger_category: "Ledger Category",
    tbl_collection_schedule: "Collections",
    tbl_report: "Reports",
    tbl_item_units: "Units",
    tbl_brand: "Brands",
    tbl_item_category: "Item Category",
    tbl_item_subcategory: "Item Subcategory",
    tbl_gst_tax_rate: "GST Tax Rate",
    tbl_credit_scheme_details: "Credit Scheme",
    tbl_line_item_status: "Sales Return Config",
    tbl_supplier_type: "Supplier Type",
    tbl_sales_manager_bdm_mapping: "Sales Manager BDM Mapping",
    tbl_bde_executive_mapping: "BDM Executive Mapping",
    tbl_customer_credit_details: "Customer",
    tbl_prefix: "Prefix",
    tbl_permissions: "Permissions",
    "tbl_menu-profile-roles": "Menu Profile Roles",
    "tbl_report-config-roles": "Reports Access",
    tbl_report_config_category: "Report Config Category",
    tbl_notification_user_types: "Notification User Types",
    tbl_notification_history: "Notification History",
    tbl_credit_note_details: "Credit Note",
    tbl_credit_note_items: "Credit Note",
    tbl_delivery_challan_details: "Delivery Challan",
    tbl_delivery_challan_items: "Delivery Challan",
    tbl_proforma_details: "Proforma Invoice",
    tbl_proforma_items: "Proforma Invoice",
    tbl_quotation_details: "Quotation",
    tbl_quotation_items: "Quotation",
    tbl_sales_return_details: "Sales Return",
    tbl_sales_return_items: "Sales Return",
    tbl_purchase_return_details: "Purchase Return",
    tbl_purchase_return_items: "Purchase Return",
    tbl_debit_note_details: "Debit Note",
    tbl_debit_note_items: "Debit Note",
    tbl_purchase_order_details: "Purchase Order",
    tbl_purchase_order_items: "Purchase Order",
    tbl_feature: "Features",
    tbl_bank_details: "Bank Details",
    tbl_prefix_history: "Prefix",
    tbl_ledger_group: "Ledger Group",
    tbl_invoice_template: "Invoice Template",
    tbl_invoice_template_config: "Invoice Template",
    tbl_invoice_template_mapping: "Invoice Template",
}