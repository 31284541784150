import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  app_runner_list: [],
  users_me: {},
  isLoading: false,
  showError: [],
  features: {},
  prefix: {},
  invoice_template: [],
  invoice_template_config: [],
  current_invoice_template: {},
  current_bank_details: {},
  invoice_template_mapping: [],
};

const AppRunnerSlice: any = createSlice({
  name: "app_runner",
  initialState,
  reducers: {
    UsersMe: (state, action) => {
      state.users_me = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    showErrorModal: (state, action) => {
      state.showError = action.payload;
    },
    features: (state, action) => {
      state.features = action.payload;
    },
    prefix: (state, action) => {
      state.prefix = action.payload;
    },
    invoice_template: (state, action) => {
      state.invoice_template = action.payload;
    },
    invoice_template_config: (state, action) => {
      state.invoice_template_config = action.payload;
    },
    current_invoice_template: (state, action) => {
      state.current_invoice_template = action.payload;
    },
    current_bank_details: (state, action) => {
      state.current_bank_details = action.payload;
    },
    invoice_template_mapping: (state, action) => {
      state.invoice_template_mapping = action.payload;
    },
  },
});

export const { UsersMe, setLoading, showErrorModal, features, prefix, invoice_template, invoice_template_config, current_invoice_template, current_bank_details, invoice_template_mapping } = AppRunnerSlice.actions;
export default AppRunnerSlice.reducer;
