import dayjs from "dayjs";
import { ACCESS_TOKEN, FilterTypes, GLOBAL_CONFIG, GLOBAL_CONFIG_KEYS } from "../constant/constant";
import { saveAs } from 'file-saver';
import Papa from 'papaparse';
import { LocalStorageUtils } from "./local-storage-utils";
import { isMobile, osName } from "mobile-device-detect";
import lodash from "lodash";
import { ComparisonOperator, CreateQueryParams, RequestQueryBuilder } from "@nestjsx/crud-request";
import Axios from "../config/axios-config";
import { toast } from "react-toastify";

export const formatDate = (date: any, format = "") => {
  return dayjs(date).format(format || JSON.parse(LocalStorageUtils.getStorage(GLOBAL_CONFIG) as string)?.[GLOBAL_CONFIG_KEYS.DATE_FORMAT] || "DD/MM/YYYY");
}

export const isAuth = () => {
  if (!LocalStorageUtils.getStorage(ACCESS_TOKEN)) return false;
  return true;
}

export const zodPatternValidation = (z: any, pattern: RegExp, message: string) => {
  return z.refine((value: any) => {
    if (value) return pattern.test(value);
    else return true;
  }, message);
}

const units = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'];
const teens = ['Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'];
const tens = ['', 'Ten', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];

const convert: any = (num: number) => {
  if (num === 0) {
    return '';
  } else if (num < 10) {
    return units[num];
  } else if (num === 10) {
    return tens[1]
  } else if (num < 20) {
    return teens[num - 11];
  } else if (num < 100) {
    return tens[Math.floor(num / 10)] + ' ' + units[num % 10];
  } else {
    return units[Math.floor(num / 100)] + ' Hundred ' + convert(num % 100);
  }
};

export const convertToIndianWords: any = (num: number) => {
  if (num === 0) {
    return 'Zero';
  } else if (num >= 10000000) {
    const remainder = num % 10000000;
    const crorePart = Math.floor(num / 10000000);
    const crore = convert(crorePart) + (crorePart > 1 ? ' Crores ' : ' Crore ');
    const rest = convertToIndianWords(remainder);
    return rest !== 'Zero' ? crore + rest : crore;
  } else if (num >= 100000) {
    const remainder = num % 100000;
    const lakhPart = Math.floor(num / 100000);
    const lakh = convert(lakhPart) + (lakhPart > 1 ? ' Lakhs ' : ' Lakh ');
    const rest = convertToIndianWords(remainder);
    return rest !== 'Zero' ? lakh + rest : lakh;
  } else if (num >= 1000) {
    const remainder = num % 1000;
    const thousandPart = Math.floor(num / 1000);
    const thousand = convert(thousandPart) + ' Thousand ';
    const rest = convert(remainder);
    return rest !== 'Zero' ? thousand + rest : thousand;
  } else {
    return convert(num);
  }
};

export const DownloadCSV = (rows: any, fileName: any) => {
  const csv = Papa.unparse(rows);
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  saveAs(blob, fileName);
};

export const formatNumber = (numberToBeFormatted: number, formatType = 'en-IN') => {
  return new Intl.NumberFormat(formatType).format(numberToBeFormatted);
}

export const readFileAsDataURL = (file: any) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = (error) => {
      reject(error);
    };

    return reader.readAsDataURL(file);
  });
};

export const getPlatform = () => {
  if (isMobile) {
    if (osName.toLowerCase() === "ios" || osName.toLowerCase() === "mac os") {
      return "ios";
    }
    else {
      return "android";
    }
  }
  else {
    return "web";
  }
}

export const getLocation = (setLocationString: any) => {
  navigator.geolocation.getCurrentPosition((position) => {
    const location = {} as any;
    location.latitude = position.coords.latitude;
    location.longitude = position.coords.longitude;
    setLocationString(`POINT(${position.coords.longitude} ${position.coords.latitude})`);
  });
}

export const filterArrayByArray = ((mainArray: any, excludeArray: any, key = "id") => {
  return lodash.differenceBy(mainArray, excludeArray, key);
});

export const getCommonObjectsBetweenArrays = ((array1: any, array2: any, key = "id") => {
  return lodash.intersectionBy(array1, array2, key);
});

export const arrayToObject = ((array: any, key = "id") => {
  return lodash.keyBy(array, key);
});

export const generateQueryString = (apiFilterObj: any, nestedTable = '', onlyCount = false) => {
  const filterObj = {} as any;

  const operator: Operator = {
    [FilterTypes.EQUAL_TO]: '$eq',
    [FilterTypes.NOT_EQUAL_TO]: '$ne',
    [FilterTypes.LIKE]: '$contL',
    [FilterTypes.GREATER_THAN]: '$gt',
    [FilterTypes.LESS_THAN]: '$lt',
    [FilterTypes.LESS_THAN_OR_EQUAL_TO]: '$lte',
    [FilterTypes.GREATER_THAN_OR_EQUAL_TO]: '$gte',
    [FilterTypes.BETWEEN]: '$between',
  }

  const findKeyMatch = (key: string): boolean => {
    const keys = [
      FilterTypes.EQUAL_TO,
      FilterTypes.NOT_EQUAL_TO,
      FilterTypes.LESS_THAN_OR_EQUAL_TO,
      FilterTypes.GREATER_THAN,
      FilterTypes.GREATER_THAN_OR_EQUAL_TO,
      FilterTypes.LESS_THAN,
      FilterTypes.BETWEEN,
    ]
    return keys.includes(key)
  }

  for (const key in apiFilterObj) {
    //filters
    if (findKeyMatch(key)) {
      for (const filterKey in apiFilterObj[key]) {
        if (Array.isArray(apiFilterObj[key][filterKey])) {
          if (key === FilterTypes.EQUAL_TO) {
            if (!filterObj.or) filterObj.or = [];
            apiFilterObj[key][filterKey].forEach((value: any) => {
              filterObj.or.push({
                field: filterKey,
                operator: operator[key],
                value
              })
            })
          } else {
            if (!filterObj.filter) filterObj.filter = [];
            apiFilterObj[key][filterKey].forEach((value: any) => {
              filterObj.filter.push({
                field: filterKey,
                operator: operator[key],
                value
              })
            })
          }
        }
        else if (key === FilterTypes.BETWEEN) {
          if (!filterObj.filter) filterObj.filter = [];
          filterObj.filter.push({
            field: filterKey,
            operator: operator[key],
            value: `${apiFilterObj[key][filterKey].from},${apiFilterObj[key][filterKey].to}`
          })
        }
        else {
          if (!filterObj.filter) filterObj.filter = [];
          const field = nestedTable ? `tbl_${nestedTable}.${filterKey}` : filterKey;
          filterObj.filter.push({
            field,
            operator: operator[key],
            value: apiFilterObj[key][filterKey]
          })
        }
      }
    }

    //sort
    else if (key === FilterTypes.SORT_BY) {
      if (!filterObj.sort) filterObj.sort = [];
      for (const filterKey in apiFilterObj[key]) {
        filterObj.sort.push({
          field: filterKey,
          order: apiFilterObj[key][filterKey]
        });
      }
    }

    //search-filter
    else if (key === FilterTypes.LIKE) {
      if (!filterObj.or) filterObj.or = [];
      Object.keys(apiFilterObj[key]).forEach((filterKey: string, index: number) => {
        if (typeof apiFilterObj[key][filterKey] === 'string') {
          const value = apiFilterObj[FilterTypes.LIKE][filterKey].replaceAll('%', '');
          const field = filterKey
          filterObj.or.push({
            field,
            operator: operator[FilterTypes.LIKE],
            value,
          })
        }
        else {
          const obj = apiFilterObj[key][filterKey];
          if (obj.nested) {
            if (Array.isArray(obj.nestedKey)) {
              const value = obj.value.replaceAll('%', '');
              obj.nestedKey.forEach((fieldKey: string) => {
                const field = `tbl_${filterKey}.${fieldKey}`;
                filterObj.or.push({
                  field,
                  operator: operator[FilterTypes.LIKE],
                  value,
                })
              })
            } else {
              const value = obj.value.replaceAll('%', '');
              const field = `tbl_${filterKey}.${obj.nestedKey}`;
              filterObj.or.push({
                field,
                operator: operator[FilterTypes.LIKE],
                value,
              })
            }
          }
        }
      })
    }

    //limit
    else if (key === FilterTypes.LIMIT) {
      filterObj.limit = +apiFilterObj[key];
    }
  }
  if (onlyCount) {
    return `${RequestQueryBuilder.create(filterObj).query()}&onlyCount`
  }
  return RequestQueryBuilder.create(filterObj).query()
}

export const joinQuery = (expandableArr: string[]) => {
  let query = '';
  expandableArr.forEach((string: string, index: number) => {
    if (index) query += '&'
    query += `join=${string}_id`
  })
  return query;
}

const removeDefaultValues = (obj: any) => {
  delete obj.id;
  delete obj.created_by;
  delete obj.created_date;
  delete obj.updated_by;
  delete obj.updated_date;
}

export const convertToInvoice = async ({ entity, dateKey, invoiceDetails, users_me, emiDetails, itemsData, prefix, navigate, props }: ConvertToInvoice) => {
  try {
    const id = invoiceDetails.id;
    const { data: salesDetailsData } = await Axios.get(`sales_details/entity?${generateQueryString({ [FilterTypes.LIKE]: { invoice_number: `%${prefix?.['Sales Invoice Prefix']?.prefix}%` } }, '', true)}`);
    invoiceDetails.invoice_number = prefix?.['Sales Invoice Prefix']?.prefix + (+(salesDetailsData.filtered_rows) + 1);
    invoiceDetails.customer_id = invoiceDetails.customer_id.id || invoiceDetails.customer_id;
    invoiceDetails.payment_mode_id = invoiceDetails.payment_mode_id.id || invoiceDetails.payment_mode_id;
    invoiceDetails.total_refund = 0;
    invoiceDetails.sales_date = invoiceDetails[dateKey];
    delete invoiceDetails[dateKey];
    delete invoiceDetails.current_status;
    delete invoiceDetails.sales_details_id;
    invoiceDetails.discount_total = +invoiceDetails.discount_total || 0;
    invoiceDetails.tax_total = +invoiceDetails.tax_total || 0;
    invoiceDetails.total_amount = +invoiceDetails.total_amount || 0;
    invoiceDetails.received_amount = +invoiceDetails.received_amount || 0;
    invoiceDetails.balance_amount = +invoiceDetails.balance_amount || 0;
    invoiceDetails.round_off_amount = +invoiceDetails.round_off_amount || 0;
    invoiceDetails.total_amount_before_round_off = +invoiceDetails.total_amount_before_round_off || 0;
    invoiceDetails.net_amount = +invoiceDetails.net_amount || 0;
    invoiceDetails.total_quantity = +invoiceDetails.total_quantity || 0;
    removeDefaultValues(invoiceDetails);
    invoiceDetails.created_by = users_me?.id;
    const salesDetailsRes = await Axios.post(`sales_details/entity`, invoiceDetails);
    removeDefaultValues(emiDetails);
    for (let i = 0; i < itemsData.length; i++) {
      const item: any = itemsData[i];
      delete item.id;
      delete item[`${entity}_details_id`];
      item.item_details_id = item.item_details_id.id;
      item.item_master_id = item.item_master_id.id;
      item.sales_details_id = salesDetailsRes.data.id;
      item.discount_amount = +item.discount_amount;
      item.mrp = +item.mrp;
      item.tax_amount = +item.tax_amount;
      item.tax_rate = +item.tax_rate;
      item.total_price = +item.total_price;
      item.unit_price = +item.unit_price;
      item.discount_rate = +item.discount_rate;
      item.quantity = +item.quantity;
      delete item?.lowStock;
      await Axios.post(`sales_items/entity`, item);
    };
    await Axios.put(`${entity}_details/entity/${id}`, { current_status: "closed", sales_details_id: salesDetailsRes.data.id });
    toast.success("Converted To Sales Invoice Successfully", {
      position: toast.POSITION.TOP_RIGHT
    });
    if (props?.isMobile) {
      navigate(`/sales-view-page/${salesDetailsRes.data.id}`,
        { state: { tableBackgroundColor: '#F9E5CF', orgNameColor: '#BF6200', isNew: true, props } })
    } else {
      navigate(`/sales-invoice-view?type=sales&id=${salesDetailsRes.data.id}`,
        { state: { tableBackgroundColor: '#F9E5CF', orgNameColor: '#BF6200', isNew: true, props } })
    }
  } catch (err) { console.log(err) };
}

export const convertToPurchaseInvoice = async ({ entity, dateKey, invoiceDetails, users_me, itemsData, prefix, navigate, props }: ConvertToInvoice) => {
  try {
    const id = invoiceDetails.id;
    const { data: purchaseDetailsData } = await Axios.get(`purchase_details/entity?${generateQueryString({ [FilterTypes.LIKE]: { invoice_number: `%${prefix?.['Purchase Invoice Prefix']?.prefix}%` } }, '', true)}`);
    invoiceDetails.invoice_number = prefix?.['Purchase Invoice Prefix']?.prefix + (+(purchaseDetailsData.filtered_rows) + 1);
    invoiceDetails.supplier_id = invoiceDetails.supplier_id.id || invoiceDetails.supplier_id;
    invoiceDetails.purchase_date = invoiceDetails[dateKey];
    delete invoiceDetails[dateKey];
    delete invoiceDetails.current_status;
    delete invoiceDetails.purchase_details_id;
    invoiceDetails.discount_total = +invoiceDetails.discount_total || 0;
    invoiceDetails.paid_amount = +invoiceDetails.paid_amount || 0;
    invoiceDetails.tax_total = +invoiceDetails.tax_total || 0;
    invoiceDetails.total_amount = +invoiceDetails.total_amount || 0;
    invoiceDetails.balance_amount = +invoiceDetails.balance_amount || 0;
    invoiceDetails.round_off_amount = +invoiceDetails.round_off_amount || 0;
    invoiceDetails.total_amount_before_round_off = +invoiceDetails.total_amount_before_round_off || 0;
    invoiceDetails.net_amount = +invoiceDetails.net_amount || 0;
    invoiceDetails.total_quantity = +invoiceDetails.total_quantity || 0;
    removeDefaultValues(invoiceDetails);
    const purchaseDetailsRes = await Axios.post(`purchase_details/entity`, invoiceDetails);
    for (let i = 0; i < itemsData.length; i++) {
      const item: any = itemsData[i];
      delete item.id;
      delete item[`${entity}_details_id`];
      item.item_details_id = item.item_details_id.id;
      item.item_master_id = item.item_master_id.id;
      item.purchase_details_id = purchaseDetailsRes.data.id;
      item.discount_amount = +item.discount_amount;
      item.mrp = +item.mrp;
      item.tax_amount = +item.tax_amount;
      item.tax_rate = +item.tax_rate;
      item.total_price = +item.total_price;
      item.unit_price = +item.unit_price;
      item.sales_price = +item.sales_price;
      item.discount_rate = +item.discount_rate;
      item.quantity = +item.quantity;
      await Axios.post(`purchase_items/entity`, item);
    };
    await Axios.put(`${entity}_details/entity/${id}`, { current_status: "closed", purchase_details_id: purchaseDetailsRes.data.id });
    toast.success("Converted To Purchase Invoice Successfully", {
      position: toast.POSITION.TOP_RIGHT
    });
    navigate(`/purchase-invoice-view?type=purchase&id=${purchaseDetailsRes.data.id}`,
      { state: { tableBackgroundColor: '#F9E5CF', orgNameColor: '#BF6200', isNew: true, props } })
  } catch (err) { console.log(err) };
}

interface Operator {
  [key: string]: ComparisonOperator
}

export interface ConvertToInvoice {
  entity: string;
  dateKey: string;
  invoiceDetails: any;
  users_me: any;
  emiDetails?: any;
  itemsData: any;
  prefix: any;
  navigate: any;
  props: any
}