import { FC, lazy, LazyExoticComponent, Suspense } from "react";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import "react-toastify/dist/ReactToastify.min.css";
import { ProtectedRoute, PublicRoute } from "./components/auth/auth";
import PageNotFound from "./components/misc/pagenotfound";
import LazyLoading from "./components/misc/lazyLoading";

//Platform routes
const CustomForm = lazy(() => import("./pages/platform/custom-form"));
const Login = lazy(() => import("./pages/platform/login"));
const Home = lazy(() => import("./pages/platform/home"));
const Users = lazy(() => import("./pages/platform/users"));
const Roles = lazy(() => import("./pages/platform/roles"));

const SalesInvoiceView = lazy(() => import("./pages/invoice/sales/sales-invoice/sales-invoice-view"));
const PurchaseInvoiceView = lazy(() => import("./pages/invoice/purchases/purchase-invoice/purchase-invoice-view"));
const ReportPage = lazy(() => import("./pages/reports/reports"));
const AddItemMobileView = lazy(() => import("./pages/mobile-view/add-item-mobile"));
const CreateCustomer = lazy(() => import("./pages/customers/mobile-view/create-customer-mobile"));
const CollectionScheduleViewPage = lazy(() => import("./pages/collection-schedule/mobile-view/collection-schedule-view-page"));
const CustomerViewPage = lazy(() => import("./pages/customers/mobile-view/customer-view-page-mobile"));
const SupplierViewPage = lazy(() => import("./pages/suppliers/mobile-view/supplier-view-page-mobile"));
const ReportAccessPage = lazy(() => import("./pages/reports/report-access"));
const SalesViewPage = lazy(() => import("./pages/invoice/sales/sales-invoice/mobile-view/sales-view-page-mobile"));
const PurchaseViewPage = lazy(() => import("./pages/invoice/purchases/purchase-invoice/mobile-view/purchase-view-page-mobile"));
const Dashboard = lazy(() => import("./pages/dashboard/dashboard"));
const EmiCollection = lazy(() => import("./pages/collection-schedule/mobile-view/list-collection-schedule-mobile"));

const SalesVoucher = lazy(() => import("./pages/invoice/sales/sales-invoice/sales-voucher"));
const PurchaseVoucher = lazy(() => import("./pages/invoice/purchases/purchase-invoice/purchase-voucher"));

//Menu Routes
const Customer = lazy(() => import("./pages/customers/customer"));
const Suppliers = lazy(() => import("./pages/suppliers/suppliers"));
const Inventory = lazy(() => import("./pages/items/items"));
const ListSalesInvoice = lazy(() => import("./pages/invoice/sales/sales-invoice/list-sales-invoice"));
const Receipts = lazy(() => import("./pages/ledger/payments-in"));
const ListPurchaseInvoice = lazy(() => import("./pages/invoice/purchases/purchase-invoice/list-purchase-invoice"));
const Payments = lazy(() => import("./pages/ledger/payments-out"));
const CollectionSchedule = lazy(() => import("./pages/collection-schedule/collection-schedule"));
const Ledgers = lazy(() => import("./pages/ledger/ledger"));
const Transactions = lazy(() => import("./pages/ledger/receipt"));
const Reports = lazy(() => import("./pages/reports/list-report-page"));
const Units = lazy(() => import("./pages/settings/units"));
const Brand = lazy(() => import("./pages/settings/brand"));
const TaxSlabs = lazy(() => import("./pages/settings/gst-tax-rate"));
const ItemCategory = lazy(() => import("./pages/settings/item-category"));
const ItemSubcategory = lazy(() => import("./pages/settings/item-sub-category"));
const CreditSchemeDetails = lazy(() => import("./pages/settings/credit-scheme-details"));
const LedgerCategory = lazy(() => import("./pages/settings/ledger-category"));
const BankDetails = lazy(() => import("./pages/settings/bank-details"));
const Prefix = lazy(() => import("./pages/settings/prefix"));
const CustomerDetails = lazy(() => import("./pages/customers/customer-details"));
const Features = lazy(() => import("./pages/settings/features"));
const ListQuotations = lazy(() => import("./pages/invoice/sales/quotation/list-quotation"));
const Quotations = lazy(() => import("./pages/invoice/sales/quotation/quotation"));
const ListSalesReturn = lazy(() => import("./pages/invoice/sales/sales-return/list-sales-return"));
const SalesReturnInvoice = lazy(() => import("./pages/invoice/sales/sales-return/sales-return-voucher"));
const ListDeliveryChallan = lazy(() => import("./pages/invoice/sales/delivery-challan/list-delivery-challan"));
const DeliveryChallan = lazy(() => import("./pages/invoice/sales/delivery-challan/delivery-challan"));
const ListCreditNote = lazy(() => import("./pages/invoice/sales/credit-note/list-credit-note"));
const CreditNote = lazy(() => import("./pages/invoice/sales/credit-note/credit-note"));
const ListProformaInvoice = lazy(() => import("./pages/invoice/sales/proforma-invoice/list-proforma-invoice"));
const ProformaInvoice = lazy(() => import("./pages/invoice/sales/proforma-invoice/proforma-invoice"));
const ListPurchaseReturn = lazy(() => import("./pages/invoice/purchases/purchase-return/list-purchase-return"));
const ListDebitNote = lazy(() => import("./pages/invoice/purchases/debit-note/list-debit-note"));
const ListPurchaseOrders = lazy(() => import("./pages/invoice/purchases/purchase-orders/list-purchase-orders"));
const PurchaseReturn = lazy(() => import("./pages/invoice/purchases/purchase-return/purchase-return-voucher"));
const DebitNote = lazy(() => import("./pages/invoice/purchases/debit-note/debit-note"));
const PurchaseOrders = lazy(() => import("./pages/invoice/purchases/purchase-orders/purchase-orders"));
const QuotationsView = lazy(() => import("./pages/invoice/sales/quotation/quotation-view"));
const SalesReturnView = lazy(() => import("./pages/invoice/sales/sales-return/sales-return-view"));
const CreditNoteView = lazy(() => import("./pages/invoice/sales/credit-note/credit-note-view"));
const DeliveryChallanView = lazy(() => import("./pages/invoice/sales/delivery-challan/delivery-challan-view"));
const ProformaInvoiceView = lazy(() => import("./pages/invoice/sales/proforma-invoice/proforma-invoice-view"));
const PurchaseReturnView = lazy(() => import("./pages/invoice/purchases/purchase-return/purchase-return-view"));
const DebitNoteView = lazy(() => import("./pages/invoice/purchases/debit-note/debit-note-view"));
const PurchaseOrdersView = lazy(() => import("./pages/invoice/purchases/purchase-orders/purchase-order-view"));
const MobileQuotationsViewPage = lazy(() => import("./pages/invoice/sales/quotation/mobile-view/quotation-view-page-mobile"));
const MobileSalesReturnViewPage = lazy(() => import("./pages/invoice/sales/sales-return/mobile-view/sales-return-view-page-mobile"));
const MobileCreditNoteViewPage = lazy(() => import("./pages/invoice/sales/credit-note/mobile-view/credit-note-view-page-mobile"));
const MobileDeliveryChallanViewPage = lazy(() => import("./pages/invoice/sales/delivery-challan/mobile-view/delivery-challan-view-page-mobile"));
const MobileProformaInvoiceViewPage = lazy(() => import("./pages/invoice/sales/proforma-invoice/mobile-view/proforma-invoice-view-page-mobile"));
const MobilePurchaseOrdersViewPage = lazy(() => import("./pages/invoice/purchases/purchase-orders/mobile-view/purchase-orders-view-page-mobile"));
const MobileDebitNoteViewPage = lazy(() => import("./pages/invoice/purchases/debit-note/mobile-view/debit-note-view-page-mobile"));
const MobilePurchaseReturnViewPage = lazy(() => import("./pages/invoice/purchases/purchase-return/mobile-view/purchase-return-view-page-mobile"));
const Expenses = lazy(() => import("./pages/expenses/expenses"));
const CreateExpenses = lazy(() => import("./pages/expenses/create-expenses"));
const InvoiceSettings = lazy(() => import("./pages/invoice-settings/invoice-settings"));
const PdfViewer = lazy(() => import("./pages/invoice/pdf/pdf-viewer"));

function getComponentWithSuspense(
  Comp: LazyExoticComponent<FC<any>>,
  props: any
): JSX.Element {
  return (
    <Suspense fallback={<LazyLoading />}>
      <Comp {...props} />
    </Suspense>
  );
}

function App() {
  const isMobile = useMediaQuery("(max-width:600px)");
  const isTab = useMediaQuery("(min-width:600px)");
  const isLaptop = useMediaQuery("(min-width:768px)");
  const isDesktop = useMediaQuery("(min-width:992px)");
  const isLarge = useMediaQuery("(min-width:1200px)");
  const props = { isMobile, isTab, isLaptop, isDesktop, isLarge };

  return (
    <Router>
      <Routes>
        <Route path="/" element={<ProtectedRoute />}>
          <Route path="" element={getComponentWithSuspense(Home, props)}>
            <Route
              path="/"
              element={getComponentWithSuspense(Dashboard, props)}
            />
            <Route
              path="/form/:menu_profile_id"
              element={getComponentWithSuspense(CustomForm, props)}
            />
            <Route
              path="/users"
              element={getComponentWithSuspense(Users, props)}
            />
            <Route
              path="/roles"
              element={getComponentWithSuspense(Roles, props)}
            />
            <Route
              path="/sales-invoice-view"
              element={getComponentWithSuspense(SalesInvoiceView, props)}
            />
            <Route
              path="/purchase-invoice-view"
              element={getComponentWithSuspense(PurchaseInvoiceView, props)}
            />
            <Route
              path="/report-view/:id"
              element={getComponentWithSuspense(ReportPage, props)}
            />
            <Route
              path="/add-item"
              element={getComponentWithSuspense(AddItemMobileView, props)}
            />
            <Route
              path="/create-customer"
              element={getComponentWithSuspense(CreateCustomer, props)}
            />
            <Route
              path="/sales-view-page/:id"
              element={getComponentWithSuspense(SalesViewPage, props)}
            />
            <Route
              path="/purchase-view-page/:id"
              element={getComponentWithSuspense(PurchaseViewPage, props)}
            />
            <Route
              path="/collection-schedule-page/:id"
              element={getComponentWithSuspense(CollectionScheduleViewPage, props)}
            />
            <Route
              path="/customer-view-page/:id"
              element={getComponentWithSuspense(CustomerViewPage, props)}
            />
            <Route
              path="/supplier-view-page/:id"
              element={getComponentWithSuspense(SupplierViewPage, props)}
            />
            <Route
              path="/report-access"
              element={getComponentWithSuspense(ReportAccessPage, props)}
            />
            <Route
              path="/emi-collection"
              element={getComponentWithSuspense(EmiCollection, props)}
            />
            <Route
              path="/customers"
              element={getComponentWithSuspense(Customer, props)}
            />
            <Route
              path="/suppliers"
              element={getComponentWithSuspense(Suppliers, props)}
            />
            <Route
              path="/inventory"
              element={getComponentWithSuspense(Inventory, props)}
            />
            <Route
              path="/list-sales-invoice"
              element={getComponentWithSuspense(ListSalesInvoice, props)}
            />
            <Route
              path="/receipts"
              element={getComponentWithSuspense(Receipts, props)}
            />
            <Route
              path="/list-purchase-invoice"
              element={getComponentWithSuspense(ListPurchaseInvoice, props)}
            />
            <Route
              path="/payments"
              element={getComponentWithSuspense(Payments, props)}
            />
            <Route
              path="/collection-schedule"
              element={getComponentWithSuspense(CollectionSchedule, props)}
            />
            <Route
              path="/ledgers"
              element={getComponentWithSuspense(Ledgers, props)}
            />
            <Route
              path="/transactions"
              element={getComponentWithSuspense(Transactions, props)}
            />
            <Route
              path="/reports"
              element={getComponentWithSuspense(Reports, props)}
            />
            <Route
              path="/item-category"
              element={getComponentWithSuspense(ItemCategory, props)}
            />
            <Route
              path="/item-subcategory"
              element={getComponentWithSuspense(ItemSubcategory, props)}
            />
            <Route
              path="/credit-scheme-details"
              element={getComponentWithSuspense(CreditSchemeDetails, props)}
            />
            <Route
              path="/ledger-category"
              element={getComponentWithSuspense(LedgerCategory, props)}
            />
            <Route
              path="/units"
              element={getComponentWithSuspense(Units, props)}
            />
            <Route
              path="/brands"
              element={getComponentWithSuspense(Brand, props)}
            />
            <Route
              path="/tax-slabs"
              element={getComponentWithSuspense(TaxSlabs, props)}
            />
            <Route
              path="/sales-voucher"
              element={getComponentWithSuspense(SalesVoucher, props)}
            />
            <Route
              path="/purchase-voucher"
              element={getComponentWithSuspense(PurchaseVoucher, props)}
            />
            <Route
              path="/bank-details"
              element={getComponentWithSuspense(BankDetails, props)}
            />
            <Route
              path="/prefix"
              element={getComponentWithSuspense(Prefix, props)}
            />
            <Route
              path="/customer-details/:id"
              element={getComponentWithSuspense(CustomerDetails, props)}
            />
            <Route
              path="/features"
              element={getComponentWithSuspense(Features, props)}
            />
            <Route
              path="/list-quotation"
              element={getComponentWithSuspense(ListQuotations, props)}
            />
            <Route
              path="/quotation"
              element={getComponentWithSuspense(Quotations, props)}
            />
            <Route
              path="/quotation-view"
              element={getComponentWithSuspense(QuotationsView, props)}
            />
            <Route
              path="/quotation-view-page/:id"
              element={getComponentWithSuspense(MobileQuotationsViewPage, props)}
            />
            <Route
              path="/list-sales-return"
              element={getComponentWithSuspense(ListSalesReturn, props)}
            />
            <Route
              path="/sales-return-invoice"
              element={getComponentWithSuspense(SalesReturnInvoice, props)}
            />
            <Route
              path="/sales-return-view"
              element={getComponentWithSuspense(SalesReturnView, props)}
            />
            <Route
              path="/sales-return-view-page/:id"
              element={getComponentWithSuspense(MobileSalesReturnViewPage, props)}
            />
            <Route
              path="/list-credit-note"
              element={getComponentWithSuspense(ListCreditNote, props)}
            />
            <Route
              path="/credit-note"
              element={getComponentWithSuspense(CreditNote, props)}
            />
            <Route
              path="/credit-note-view"
              element={getComponentWithSuspense(CreditNoteView, props)}
            />
            <Route
              path="/credit-note-view-page/:id"
              element={getComponentWithSuspense(MobileCreditNoteViewPage, props)}
            />
            <Route
              path="/list-delivery-challan"
              element={getComponentWithSuspense(ListDeliveryChallan, props)}
            />
            <Route
              path="/delivery-challan"
              element={getComponentWithSuspense(DeliveryChallan, props)}
            />
            <Route
              path="/delivery-challan-view"
              element={getComponentWithSuspense(DeliveryChallanView, props)}
            />
            <Route
              path="/delivery-challan-view-page/:id"
              element={getComponentWithSuspense(MobileDeliveryChallanViewPage, props)}
            />
            <Route
              path="/list-proforma-invoice"
              element={getComponentWithSuspense(ListProformaInvoice, props)}
            />
            <Route
              path="/proforma-invoice"
              element={getComponentWithSuspense(ProformaInvoice, props)}
            />
            <Route
              path="/proforma-invoice-view"
              element={getComponentWithSuspense(ProformaInvoiceView, props)}
            />
            <Route
              path="/proforma-invoice-view-page/:id"
              element={getComponentWithSuspense(MobileProformaInvoiceViewPage, props)}
            />
            <Route
              path="/list-purchase-return"
              element={getComponentWithSuspense(ListPurchaseReturn, props)}
            />
            <Route
              path="/purchase-return-invoice"
              element={getComponentWithSuspense(PurchaseReturn, props)}
            />
            <Route
              path="/purchase-return-view"
              element={getComponentWithSuspense(PurchaseReturnView, props)}
            />
            <Route
              path="/list-debit-note"
              element={getComponentWithSuspense(ListDebitNote, props)}
            />
            <Route
              path="/debit-note"
              element={getComponentWithSuspense(DebitNote, props)}
            />
            <Route
              path="/debit-note-view"
              element={getComponentWithSuspense(DebitNoteView, props)}
            />
            <Route
              path="/debit-note-view-page/:id"
              element={getComponentWithSuspense(MobileDebitNoteViewPage, props)}
            />
            <Route
              path="/list-purchase-orders"
              element={getComponentWithSuspense(ListPurchaseOrders, props)}
            />
            <Route
              path="/purchase-order-view-page/:id"
              element={getComponentWithSuspense(MobilePurchaseOrdersViewPage, props)}
            />
            <Route
              path="/purchase-order"
              element={getComponentWithSuspense(PurchaseOrders, props)}
            />
            <Route
              path="/purchase-order-view"
              element={getComponentWithSuspense(PurchaseOrdersView, props)}
            />
            <Route
              path="/purchase-return-view-page/:id"
              element={getComponentWithSuspense(MobilePurchaseReturnViewPage, props)}
            />
            <Route
              path="roles"
              element={getComponentWithSuspense(Roles, props)}
            />
            <Route
              path="expenses"
              element={getComponentWithSuspense(Expenses, props)}
            />
            <Route
              path="create-expenses"
              element={getComponentWithSuspense(CreateExpenses, props)}
            />
            <Route
              path="invoice-settings"
              element={getComponentWithSuspense(InvoiceSettings, props)}
            />
            <Route
              path="view-pdf"
              element={getComponentWithSuspense(PdfViewer, props)}
            />
          </Route>
        </Route>
        <Route path="/login" element={<PublicRoute />}>
          <Route path="" element={getComponentWithSuspense(Login, props)} />
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
